import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Line } from '../classes/line';

@Injectable({
  providedIn: 'root'
})
export class LineService {

  constructor(private _http: HttpClient) { }

  findAll(carrier_id: string): Observable<Line[]> {
    return this._http.get<Line[]>(
      environment.api_url + "pass/lines/" + carrier_id)
  }

  findBySymbols(carrier_id: string, symbols: string) : Observable<Line[]> {
    let url = environment.api_url + "pass/lines/by_symbols/" + carrier_id + "/" + symbols;
    if (symbols == null || symbols == "") return this._http.get<Line[]>(url + "asdasdad");
    symbols = this.replaceAll(symbols, ";", "+");
    url = environment.api_url + "pass/lines/by_symbols/" + carrier_id + "/" + symbols;
    console.log("url: " + url);
    console.log("LIN SYM: " + symbols);
    return this._http.get<Line[]>(url)
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  findOne(id: string) {
    return this._http.get<Line>(
      environment.api_url + "pass/lines/one/" + id);
  }

}
