import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { forkJoin, Observable } from 'rxjs';
import { StopPoint } from 'src/app/classes/stop_point';
import { StopPointService } from 'src/app/services/stop-point.service';
import { LineVariantStopPointListComponent } from '../line-variant-stop-point-list/line-variant-stop-point-list.component';
import { DeparturesDisplayComponent } from '../../departures/departures-display/departures-display.component';
import { DeparturesTimetableComponent } from '../../departures/departures-timetable/departures-timetable.component';
import { Settings } from 'src/app/classes/settings';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-stop-point-list',
  templateUrl: './stop-point-list.component.html',
  styleUrls: ['./stop-point-list.component.less']
})
export class StopPointListComponent implements OnInit {

  loading = false;
  carrier_id: string = null;
  settings: Settings = null;

  myControl = new FormControl();
  stops: StopPoint[] = [];
  filteredStops: Observable<string[]>;

  _label_close = "Zamknij";
  _label_header = "Wybierz przystanek";
  _label_nearest = "Najbliższe";
  _label_timetable = "Rozkład";
  _label_filter = "Filtruj...";

  public dataSource: MatTableDataSource<StopPoint> = new MatTableDataSource<StopPoint>([]);

  displayedColumns: string[] = ['name', 'actions'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<LineVariantStopPointListComponent>,
    private dialog: MatDialog,
    private _mainService: StopPointService) { }

  ngOnInit() {
    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this.updateLabels();

    this.carrier_id = localStorage.getItem("_pass_carrier_id");

    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      let low = filter.toLowerCase();
      let lines_cont = false;
      let dir_cont = false;
      data.lines.forEach(l => {
        if (l.toLowerCase().includes(low)) {
          lines_cont = true;
        }
      })
      data.directions.forEach(l => {
        if (l.toLowerCase().includes(low)) {
          dir_cont = true;
        }
      })

      return lines_cont || dir_cont || data.displ_name.toLowerCase().includes(filter) ||
        (data.street && data.street.name.toLowerCase().includes(filter));
    };

    if (this.carrier_id) {
      this.refresh();
    }

    this.filteredStops = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? this._filter(value) : [])
      );

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    let x = this.stops.filter(stop => stop.displ_name.toLowerCase().includes(filterValue));
    var y: string[] = []
    x.forEach(a => {
      y.push(a.displ_name)
    })

    this.dataSource.data = x;
    return y;
  }

  refresh() {
    this.loading = true;
    forkJoin([
      this._mainService.findAll(this.carrier_id)
    ]).subscribe(results => {
      this.dataSource.data = results[0];
      this.stops = results[0];
      this.loading = false;
    });
  }

  stopClick1(stop: StopPoint) {
    //this.dialogRef.close();
    localStorage.setItem("_pass_deps_stop_point_id", stop.id);
    localStorage.setItem("_pass_deps_stop_name", stop.displ_name);
    this.dialog.open(DeparturesDisplayComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  stopClick2(stop: StopPoint) {
    //this.dialogRef.close();
    localStorage.setItem("_pass_deps_stop_point_id", stop.id);
    localStorage.setItem("_pass_deps_stop_name", stop.displ_name);
    this.dialog.open(DeparturesTimetableComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_close = "Zamknij";
      this._label_header = "Wybierz przystanek";
      this._label_nearest = "Najbliższe";
      this._label_timetable = "Rozkład";
      this._label_filter = "Filtruj...";
    } else if (this.settings.language == "en") {
      this._label_close = "Close";
      this._label_header = "Choose bus stop";
      this._label_nearest = "Nearest";
      this._label_timetable = "Timetable";
      this._label_filter = "Filter...";
    } else {
      this._label_close = "Ende";
      this._label_header = "Bushaltestelle auswählen";
      this._label_nearest = "Bevorstehende Abfahrten";
      this._label_timetable = "Fahrplan";
      this._label_filter = "Filter...";
    }
  }

}
