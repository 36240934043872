import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-shared-info',
  templateUrl: './shared-info.component.html',
  styleUrls: ['./shared-info.component.less']
})
export class SharedInfoComponent implements OnInit {

  public question: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.question = data.question;
  }

  ngOnInit() {
  }

}
