import { Injectable } from '@angular/core';
import { DayType } from '../classes/day_type';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DayTypeService {

  constructor(private _http: HttpClient) { }

  findAll(carrier_id: string): Observable<DayType[]> {
    return this._http.get<DayType[]>(
      environment.api_url + "pass/day_types/" + carrier_id)
  }
  
}
