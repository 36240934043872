import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { StopPointService } from 'src/app/services/stop-point.service';
import { DeparturesDisplayComponent } from '../../departures/departures-display/departures-display.component';
import { StopPointListComponent } from '../../stop/stop-point-list/stop-point-list.component';

@Component({
  template: ''
})
export class OpenStopComponent {

  private sub: any;

  constructor(public dialog: MatDialog, private router: Router,
    private _stopPointService: StopPointService,
    private route: ActivatedRoute) {
    this.openDialog();
  }
  openDialog(): void {
    this.sub = this.route.params.subscribe(params => {
      let id = params['id'];

      this._stopPointService.findOne(id).subscribe(result => {
        localStorage.setItem("_pass_deps_stop_point_id", id);
        localStorage.setItem("_pass_deps_stop_name", result.displ_name);
        const dialogRef = this.dialog.open(DeparturesDisplayComponent, {width: '100vw',
        height: '100vh'});
        dialogRef.afterClosed().subscribe(result => {
          this.router.navigate(['../'], { relativeTo: this.route });
        });
      });

     
   }); 
  }
}