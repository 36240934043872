import { Component, OnInit } from '@angular/core';
import { Settings } from 'src/app/classes/settings';
import { StopPoint } from 'src/app/classes/stop_point';
import { DeparturesTimetableComponent } from '../../departures/departures-timetable/departures-timetable.component';
import { DeparturesDisplayComponent } from '../../departures/departures-display/departures-display.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FavoritesService } from 'src/app/services/favorites.service';
import { Line } from 'src/app/classes/line';
import { forkJoin } from 'rxjs';
import { LineVariantListComponent } from '../../line/line-variant-list/line-variant-list.component';

@Component({
  selector: 'app-favorites-list',
  templateUrl: './favorites-list.component.html',
  styleUrls: ['./favorites-list.component.less']
})
export class FavoritesListComponent implements OnInit {

  _label_favorite_stops: string = "";
  _label_favorite_lines: string = "";

  _label_close: string;
  _label_header: string;
  _label_nearest: string;
  _label_timetable: string;
  _label_direction: string;
  _label_zero_stops = "Brak ulubionych przystanków";
  _label_zero_lines: string;
  _label_directions: string;

  favoriteStops: StopPoint[] = [];
  favoriteLines: Line[] = [];

  carrier_id: string;

  settings: Settings;

  constructor(private dialogRef: MatDialogRef<FavoritesListComponent>,
    private _favoriteService: FavoritesService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this.updateLabels();

    this.carrier_id = localStorage.getItem("_pass_carrier_id");

    this.reload();

    
  }

  reload() {
    forkJoin([
      this._favoriteService.getStops(this.carrier_id),
      this._favoriteService.getLines(this.carrier_id)
    ]).subscribe(results => {
      if (results[0] == null) this.favoriteStops = [];
      else this.favoriteStops = results[0];
      if (results[1] == null) this.favoriteLines = [];
      else this.favoriteLines = results[1];
      console.log(this.favoriteStops);
      console.log(this.favoriteLines);
    });
  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_close = "Zamknij";
      this._label_header = "Wybierz przystanek";
      this._label_nearest = "Najbliższe";
      this._label_timetable = "Rozkład";
      this._label_favorite_stops = "Ulubione przystanki";
      this._label_favorite_lines = "Ulubione linie";
      this._label_direction = "Kierunek";
      this._label_zero_stops = "Brak ulubionych przystanków";
      this._label_zero_lines = "Brak ulubionych linii";
      this._label_directions = "Kierunki";
    } else if (this.settings.language == "en") {
      this._label_close = "Close";
      this._label_header = "Choose bus stop";
      this._label_nearest = "Nearest";
      this._label_timetable = "Timetable";
      this._label_favorite_stops = "Favorite stops";
      this._label_favorite_lines = "Favorite lines";
      this._label_direction = "Direction";
      this._label_zero_stops = "No favorite stops";
      this._label_zero_lines = "No favorite lines";
      this._label_directions = "Directions";
    } else {
      this._label_close = "Ende";
      this._label_header = "Wahlen Sie ...";
      this._label_nearest = "Bevorstehende Abfahrten";
      this._label_timetable = "Fahrplan";
      this._label_favorite_stops = "Favoriten Bushaltestellen";
      this._label_favorite_lines = "Favoriten Linien";
      this._label_direction = "Direktion";
      this._label_zero_stops = "Keine Lieblingsbushaltestellen";
      this._label_zero_lines = "Keine Lieblingslinien";
      this._label_directions = "Direktionen";
    }
  }

  stopClick1(stop: StopPoint) {
    this.dialogRef.close();
    localStorage.setItem("_pass_deps_stop_point_id", stop.id);
    localStorage.setItem("_pass_deps_stop_name", stop.displ_name);
    this.dialog.open(DeparturesDisplayComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  stopClick2(stop: StopPoint) {
    this.dialogRef.close();
    localStorage.setItem("_pass_deps_stop_point_id", stop.id);
    localStorage.setItem("_pass_deps_stop_name", stop.displ_name);
    this.dialog.open(DeparturesTimetableComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  lineClick(line: Line) {
    this.dialogRef.close();
    localStorage.setItem("_pass_line_id", line.id);
    this.dialog.open(LineVariantListComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  deleteFavoriteStop(stop: StopPoint) {
    this._favoriteService.removeStopPoint(stop).subscribe(r => {
      localStorage.setItem('_pass_acc_info', JSON.stringify(r));
      this.reload();
    })
  }

  deleteFavoriteLine(line: Line) {
    this._favoriteService.removeLine(line).subscribe(r => {
      localStorage.setItem('_pass_acc_info', JSON.stringify(r));
      this.reload();
    })
  }

}
