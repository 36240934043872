import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { StopPoint } from 'src/app/classes/stop_point';
import { StopPointService } from 'src/app/services/stop-point.service';
import { forkJoin } from 'rxjs';
import { DeparturesDisplayComponent } from '../../departures/departures-display/departures-display.component';
import { DeparturesTimetableComponent } from '../../departures/departures-timetable/departures-timetable.component';
import { Settings } from 'src/app/classes/settings';
import { LineVariantService } from 'src/app/services/line-variant.service';
import { FavoritesService } from 'src/app/services/favorites.service';

@Component({
  selector: 'app-line-variant-stop-point-list',
  templateUrl: './line-variant-stop-point-list.component.html',
  styleUrls: ['./line-variant-stop-point-list.component.less']
})
export class LineVariantStopPointListComponent implements OnInit {

  loading = false;
  carrier_id: string = null;
  settings: Settings = null;
  line_variant_id: string = null;

  _label_close = "Zamknij";
  _label_header = "Wybierz przystanek";
  _label_nearest = "Najbliższe";
  _label_timetable = "Rozkład";
  _label_line_favorite = "Dodaj linię do ulubiomnych";
  _label_filter = "Filter...";

  public dataSource: MatTableDataSource<StopPoint> = new MatTableDataSource<StopPoint>([]);

  displayedColumns: string[] = ['name', 'actions'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<LineVariantStopPointListComponent>,
    private dialog: MatDialog,
    private _favoritesService: FavoritesService,
    private _lineVariantService: LineVariantService,
    private _mainService: StopPointService) { }

  ngOnInit() {
    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this.updateLabels();

    this.carrier_id = localStorage.getItem("_pass_carrier_id");
    this.line_variant_id = localStorage.getItem("_pass_line_variant_id");

    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return data.name.toLowerCase().includes(filter);
    };

    if (this.carrier_id && this.line_variant_id) {
      this.refresh();
    }
  }

  refresh() {
    this.loading = true;
    forkJoin([
      this._mainService.findAllByVariant(this.carrier_id, this.line_variant_id)
    ]).subscribe(results => {
      this.dataSource.data = results[0];
      this.loading = false;
    });
  }

  stopClick1(stop: StopPoint) {
   // this.dialogRef.close();
    localStorage.setItem("_pass_deps_stop_point_id", stop.id);
    localStorage.setItem("_pass_deps_stop_name", stop.displ_name);
    this.dialog.open(DeparturesDisplayComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  stopClick2(stop: StopPoint) {
   // this.dialogRef.close();
    localStorage.setItem("_pass_deps_stop_point_id", stop.id);
    localStorage.setItem("_pass_deps_stop_name", stop.displ_name);
    this.dialog.open(DeparturesTimetableComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_close = "Zamknij";
      this._label_header = "Wybierz przystanek";
      this._label_nearest = "Najbliższe";
      this._label_timetable = "Rozkład";
      this._label_line_favorite = "Dodaj do ulubionych";
      this._label_filter = "Filtruj...";
    } else if (this.settings.language == "en") {
      this._label_close = "Close";
      this._label_header = "Choose bus stop";
      this._label_nearest = "Nearest";
      this._label_timetable = "Timetable";
      this._label_line_favorite = "Add to favorites";
      this._label_filter = "Filter...";
    } else {
      this._label_close = "Ende";
      this._label_header = "Bushaltestelle wählen";
      this._label_nearest = "Bevorstehende Abfahrten";
      this._label_timetable = "Fahrplan";
      this._label_line_favorite = "Zeile zu Favoriten hinzufügen";
      this._label_filter = "Filter...";
    }
  }

  
  
}
