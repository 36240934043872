import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StopPoint } from '../classes/stop_point';

@Injectable({
  providedIn: 'root'
})
export class StopPointService {

  constructor(private _http: HttpClient) { }

  findAll(carrier_id: string): Observable<StopPoint[]> {
    return this._http.get<StopPoint[]>(
      environment.api_url + "pass/stop_points/" + carrier_id)
  }

  findAllByVariant(carrier_id: string, variant_id: string): Observable<StopPoint[]> {
    return this._http.get<StopPoint[]>(
      environment.api_url + "pass/stop_points/" + carrier_id + "/by_variant/" + variant_id)
  }

  findOne(id: string) {
    return this._http.get<StopPoint>(
      environment.api_url + "pass/stop_points/one/" + id);
  }

  findBySymbols(carrier_id: string, symbols: string) : Observable<StopPoint[]> {
    let url = environment.api_url + "pass/stop_points/by_symbols/" + carrier_id + "/" + symbols;
    if (symbols == null || symbols == "") return this._http.get<StopPoint[]>(url + "asdasdad");
    symbols = this.replaceAll(symbols, ";", "+");
    url = environment.api_url + "pass/stop_points/by_symbols/" + carrier_id + "/" + symbols;
    console.log("SP SYM: " + symbols);
    return this._http.get<StopPoint[]>(url);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

}
