import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { OpenStopComponent } from './components/help/open-stop/open-stop.component';

const routes: Routes = [
  {path: '*', component: AppComponent, pathMatch: 'full'},
  {path: 'bus_stop_no/:id', component: OpenStopComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
