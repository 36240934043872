import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Departure } from '../classes/departure';

@Injectable({
  providedIn: 'root'
})
export class DepartureService {

  constructor(private _http: HttpClient) { }

  findByDayType(carrier_id: string, stop_point_id: string, day_type_id: string): Observable<Departure[]> {
    return this._http.get<Departure[]>(
      environment.api_url + "pass/departures/day_type/" + carrier_id + "/" + stop_point_id + "/" + day_type_id);
  }

  findByDate(carrier_id: string, stop_point_id: string, date: string): Observable<Departure[]> {
    return this._http.get<Departure[]>(
      environment.api_url + "pass/departures/date/" + carrier_id + "/" + stop_point_id + "/" + date);
  }

  findNearest(carrier_id: string, stop_point_id: string): Observable<Departure[]> {
    return this._http.get<Departure[]>(
      environment.api_url + "pass/departures/nearest/" + carrier_id + "/" + stop_point_id);
  }

}
