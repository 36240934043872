import { Favorites } from '../classes/favorites';
import { Injectable } from '@angular/core';
import { StopPoint } from '../classes/stop_point';
import { LineVariant } from '../classes/line_variant';
import { Line } from '../classes/line';
import { AccountLoginResponse, AccountLoginRequest } from '../classes/account_login';
import { AccountService } from './account.service';
import { StopPointService } from './stop-point.service';
import { LineService } from './line.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FavoritesService {

    constructor(private _accountService: AccountService,
        private _stopService: StopPointService,
        private _lineService: LineService) { }

    /*addStopPoint(stop_point: StopPoint) {
        let fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        if (fav == null) {
            localStorage.setItem("_pass_fav", JSON.stringify(new Favorites()));
        }
        fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        let found = false;
        fav.stop_points.forEach(s => {
            if (s.id == stop_point.id) {
                found = true;
            }
        })
        if (!found) {
            fav.stop_points.push(stop_point);
        }
        console.log(fav);
        localStorage.setItem("_pass_fav", JSON.stringify(fav));
    }

    addLine(line_variant: LineVariant) {
        let fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        if (fav == null) {
            localStorage.setItem("_pass_fav", JSON.stringify(new Favorites()));
        }
        fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        let found = false;
        fav.lines.forEach(s => {
            if (s.id == line_variant.id) {
                found = true;
            }
        })
        if (!found) {
            fav.lines.push(line_variant);
        }
        localStorage.setItem("_pass_fav", JSON.stringify(fav));
    }

    removeStopPoint(stop_point_id: string) {
        let fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        let index = -1;
        let i = 0;
        fav.stop_points.forEach(s => {
            if (s.id == stop_point_id) {
                index = i;
            }
            i = i + 1;
        });
        if (index > -1) {
            fav.stop_points.splice(index, 1);
        }
        localStorage.setItem("_pass_fav", JSON.stringify(fav));
    }

    removeLine(line_id: string) {
        let fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        let index = -1;
        let i = 0;
        fav.lines.forEach(s => {
            if (s.id == line_id) {
                index = i;
            }
            i = i + 1;
        });
        if (index > -1) {
            fav.lines.splice(index, 1);
        }
        localStorage.setItem("_pass_fav", JSON.stringify(fav));
    }

    getStops() : StopPoint[] {
        let fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        if (fav == null) {
            localStorage.setItem("_pass_fav", JSON.stringify(new Favorites()));
        }
        fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        return fav.stop_points;
    }

    getLines() : LineVariant[] {
        let fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        if (fav == null) {
            localStorage.setItem("_pass_fav", JSON.stringify(new Favorites()));
        }
        fav = <Favorites>JSON.parse(localStorage.getItem("_pass_fav"));
        return fav.lines;
    }*/

    addStopPoint(stop_point: StopPoint) {
        let _pass_acc_info = <AccountLoginResponse>(JSON.parse(localStorage.getItem('_pass_acc_info')));

        let symbol = stop_point.symbol;

        if (_pass_acc_info.fav_stop_points.includes(symbol + ";")) {
            return;
        }
        let request = new AccountLoginRequest();
        request.login = _pass_acc_info.login;
        request.password = _pass_acc_info.password;
        request.fav_stop_points = _pass_acc_info.fav_stop_points + symbol + ";";
        request.fav_lines = _pass_acc_info.fav_lines;

        this._accountService.update(request).subscribe(r => {
            console.log("PO DOD");
            console.log(r);
            localStorage.setItem('_pass_acc_info', JSON.stringify(r));

            let _pass_acc_info2 = <AccountLoginResponse>(JSON.parse(localStorage.getItem('_pass_acc_info')));
            console.log(_pass_acc_info2);
        });
    }

    addLine(line: Line) {
        let _pass_acc_info = <AccountLoginResponse>(JSON.parse(localStorage.getItem('_pass_acc_info')));

        let symbol = line.symbol;

        if (_pass_acc_info.fav_lines.includes(symbol + ";")) {
            return;
        }

        let request = new AccountLoginRequest();
        request.login = _pass_acc_info.login;
        request.password = _pass_acc_info.password;
        request.fav_stop_points = _pass_acc_info.fav_stop_points;
        request.fav_lines = _pass_acc_info.fav_lines + symbol + ";";

        this._accountService.update(request).subscribe(r => {
            console.log("PO DOD");
            console.log(r);
            localStorage.setItem('_pass_acc_info', JSON.stringify(r));

            let _pass_acc_info2 = <AccountLoginResponse>(JSON.parse(localStorage.getItem('_pass_acc_info')));
            console.log(_pass_acc_info2);
        });
    }

    removeStopPoint(stop_point: StopPoint) {
        let _pass_acc_info = <AccountLoginResponse>(JSON.parse(localStorage.getItem('_pass_acc_info')));

        let symbol = stop_point.symbol;
        let request = new AccountLoginRequest();
        request.login = _pass_acc_info.login;
        request.password = _pass_acc_info.password;
        request.fav_stop_points = _pass_acc_info.fav_stop_points.replace(symbol + ";", '');
        request.fav_lines = _pass_acc_info.fav_lines + symbol + ";";

        return this._accountService.update(request);
    }

    removeLine(line: Line) {
        let _pass_acc_info = <AccountLoginResponse>(JSON.parse(localStorage.getItem('_pass_acc_info')));

        let symbol = line.symbol;
        let request = new AccountLoginRequest();
        request.login = _pass_acc_info.login;
        request.password = _pass_acc_info.password;
        request.fav_lines = _pass_acc_info.fav_lines.replace(symbol + ";", '');
        request.fav_stop_points = _pass_acc_info.fav_stop_points;

        return this._accountService.update(request);
    }

    getStops(cid: string) : Observable<StopPoint[]> {
        let _pass_acc_info = <AccountLoginResponse>(JSON.parse(localStorage.getItem('_pass_acc_info')));
        console.log("UUU");
        console.log(_pass_acc_info);
        console.log(_pass_acc_info.fav_stop_points);
        if (_pass_acc_info == null) {
            return null;
        }
        return this._stopService.findBySymbols(cid, _pass_acc_info.fav_stop_points);
    }

    getLines(cid: string) : Observable<Line[]> {
        let _pass_acc_info = <AccountLoginResponse>(JSON.parse(localStorage.getItem('_pass_acc_info')));
        console.log("UUU");
        console.log(_pass_acc_info);
        console.log(_pass_acc_info.fav_lines);
        if (_pass_acc_info == null) {
            return null;
        }
        return this._lineService.findBySymbols(cid, _pass_acc_info.fav_lines);
    }

}