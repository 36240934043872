import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { LineVariant } from 'src/app/classes/line_variant';
import { LineVariantService } from 'src/app/services/line-variant.service';
import { MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
import { LineListComponent } from '../line-list/line-list.component';
import { LineVariantStopPointListComponent } from '../../stop/line-variant-stop-point-list/line-variant-stop-point-list.component';
import { Settings } from 'src/app/classes/settings';
import { LineService } from 'src/app/services/line.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { AccountLoginResponse } from 'src/app/classes/account_login';
import { SharedInfoComponent } from '../../shared/shared-info/shared-info.component';

@Component({
  selector: 'app-line-variant-list',
  templateUrl: './line-variant-list.component.html',
  styleUrls: ['./line-variant-list.component.less']
})
export class LineVariantListComponent implements OnInit {

  loading = false;

  carrier_id: string = null;
  line_id: string = null;
  settings: Settings = null;

  acc_info: AccountLoginResponse = null;

  _label_close = "Zamknij";
  _label_header = "Wybierz kierunek";
  _label_filter = "Filter...";
  _label_line_favorite = "Dodaj linię do ulubiomnych";

  public dataSource: MatTableDataSource<LineVariant> = new MatTableDataSource<LineVariant>([]);

  displayedColumns: string[] = ['name'];

  constructor(private dialogRef: MatDialogRef<LineListComponent>,
    private dialog: MatDialog,
    private _favoritesService: FavoritesService,
    private _lineService: LineService,
    private _lineVariantService: LineVariantService) { }

  ngOnInit() {
    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this.acc_info = JSON.parse(localStorage.getItem("_pass_acc_info"));
    this.updateLabels();

    this.carrier_id = localStorage.getItem("_pass_carrier_id");
    this.line_id = localStorage.getItem("_pass_line_id");

    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return data.direction.toLowerCase().includes(filter);
    };

    if (this.carrier_id && this.line_id) {
      this.refresh();
    }
  }

  refresh() {
    forkJoin([
      this._lineVariantService.findAllByLineId(this.carrier_id, this.line_id)
    ]).subscribe(results => {
      
      let ret = [];
      let a = results[0];
      console.log("A")
      console.log(a)
      a.forEach(l => {
        let f = false;
        ret.forEach(x => {
          if (x.direction == l.direction && x.direction != "") {
            f = true;
          }
        });
        if (!f) {
          ret.push(l);
        }
      });
      this.dataSource.data = ret;
    });
  }

  lineClick(variant: LineVariant) {
    //this.dialogRef.close();
    localStorage.setItem("_pass_line_variant_id", variant.id);
    this.dialog.open(LineVariantStopPointListComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_close = "Zamknij";
      this._label_header = "Wybierz kierunek";
      this._label_filter = "Filtruj...";
      this._label_line_favorite = "Dodaj linię do ulubiomnych";
    } else if (this.settings.language == "en") {
      this._label_close = "Close";
      this._label_header = "Choose a direction";
      this._label_filter = "Filter...";
      this._label_line_favorite = "Add to favorites";
    } else {
      this._label_close = "Ende";
      this._label_header = "Wahlen Sie einen direktion";
      this._label_filter = "Filter...";
      this._label_line_favorite = "Zeile zu Favoriten hinzufügen";
    }
  }

  addToFavorite() {
    if (this.line_id == null) {
      return;
    }

    this._lineService.findOne(this.line_id).subscribe(result => {
      this._favoritesService.addLine(result);
      this.dialog.open(SharedInfoComponent, {
        maxWidth: '500px',
        data: {
          question: 'Dodano linię do ulubionych'
        }
      });
    });
    
  }

}
