import { Component, OnInit, ViewChild } from '@angular/core';
import { Carrier } from 'src/app/classes/carrier';
import { CarrierService } from 'src/app/services/carrier.service';
import { MatDialogRef } from '@angular/material';
import { forkJoin } from 'rxjs';
import { Settings } from 'src/app/classes/settings';

@Component({
  selector: 'app-change-carrier',
  templateUrl: './change-carrier.component.html',
  styleUrls: ['./change-carrier.component.less']
})
export class ChangeCarrierComponent implements OnInit {

  loading = false;
  carrier_id: string = null;

  carriers: Carrier[] = [];
  settings: Settings = null;

  _label_close = "Zamknij";
  _label_header = "Wybierz przewoźnika";

  constructor(public dialogRef: MatDialogRef<ChangeCarrierComponent>,
    private _mainService: CarrierService) { }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this.updateLabels();
    this.loading = true;
    forkJoin([
      this._mainService.findAll()
    ]).subscribe(results => {
      let r = results[0];
      r.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      this.carriers = r;
      this.loading = false;
    });
  }

  clickCarrier(carrier: Carrier) {
    console.log(carrier);
    localStorage.setItem("_pass_carrier_id", carrier.id);
    console.log(localStorage.getItem("_pass_carrier_id"));

    this.dialogRef.close();
  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_close = "Zamknij";
      this._label_header = "Wybierz przewoźnika";
    } else if (this.settings.language == "en") {
      this._label_close = "Close";
      this._label_header = "Choose carrier";
    } else {
      this._label_close = "Ende";
      this._label_header = "Wählen Sie einen Spediteur"
    }
  }

}
