import { Component, OnInit } from '@angular/core';
import { DayType } from 'src/app/classes/day_type';
import { DepartureService } from 'src/app/services/departure.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { forkJoin } from 'rxjs';
import { DayTypeService } from 'src/app/services/day-type.service';
import { Departure } from 'src/app/classes/departure';
import { DatePipeService } from '../../../services/date-pipe.service';
import { TempDeps } from 'src/app/classes/tempdeps';
import { Settings } from 'src/app/classes/settings';
import { StopPointService } from 'src/app/services/stop-point.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDialog } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { AccountLoginResponse } from 'src/app/classes/account_login';
import { SharedInfoComponent } from '../../shared/shared-info/shared-info.component';

@Component({
  selector: 'app-departures-timetable',
  templateUrl: './departures-timetable.component.html',
  styleUrls: ['./departures-timetable.component.less'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
  ]
})
export class DeparturesTimetableComponent implements OnInit {

  _loading = false;

  dateSelected: Date = new Date();

  dayTypes: DayType[] = [];

  stop_point_id: string = null;
  stop_name: string = null;
  carrier_id: string = null;
  settings: Settings = null;
  acc_info: AccountLoginResponse = null;

  tempdeps: TempDeps[] = [];

  _label_close = "Zamknij";
  _label_header = "Odjazdy dla";
  _label_date_choose = "Wybierz datę";
  _label_direction = "KIERUNEK";
  _label_select = "Pokaż";
  _label_favorite = "Dodaj przystanek do ulubionych";

  constructor(private _departureService: DepartureService,
    private _favoritesService: FavoritesService,
    private _dayTypeService: DayTypeService,
    private _stopPointService: StopPointService,
    private _dateFormatPipe: DatePipeService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this.acc_info = JSON.parse(localStorage.getItem("_pass_acc_info"));
    this.updateLabels();

    this.stop_point_id = localStorage.getItem("_pass_deps_stop_point_id");
    this.stop_name = localStorage.getItem("_pass_deps_stop_name");
    this.carrier_id = localStorage.getItem("_pass_carrier_id");

    forkJoin([
      this._dayTypeService.findAll(this.carrier_id),
    ]).subscribe(results => {
      this.dayTypes = results[0];
    });
  }

  dateClick() {
    if (this.dateSelected) {
      this._loading = true;
      let d = this._dateFormatPipe.transform(this.dateSelected);
      console.log(d);
      this._departureService.findByDate(this.carrier_id, this.stop_point_id, d).subscribe(result => {
        try {
          this.processDepartures(result);
        } catch (ex) {
          console.log(ex);
        }
        this._loading = false;
      });
    }
  }

  processDepartures(deps: Departure[]) {
    this.tempdeps = [];
    console.log(deps);
    deps.forEach(d => {
      let ind = this.tempLineExists(d.line_name, d.direction);
      if (ind > -1) {
        let tde = this.tempdeps[ind].deps;
        tde.push(d);
        this.tempdeps[ind].deps = tde;
      } else {
        let tt = new TempDeps();
        tt.deps = [];
        tt.deps.push(d);
        tt.line_name = d.line_name;
        tt.direction = d.direction;
        this.tempdeps.push(tt);
      }
    });
    this.tempdeps.sort((obj1, obj2) => {
      var res1 = +obj1.line_name.replace(/\D/g, "");
      var res2 = +obj2.line_name.replace(/\D/g, "");

      if (res1 > res2) {
        return 1;
      }
      if (res1 < res2) {
        return -1;
      }
      return 0;
    });
  }

  tempLineExists(line_name: string, direction: string) {
    let i = 0;
    let ret = -1;
    this.tempdeps.forEach(t => {
      if (t.line_name == line_name && t.direction == direction) {
        ret = i;
      }
      i++;
    });
    return ret;
  }

  addToFavorite() {
    if (this.stop_point_id == null) {
      return;
    }

    this._stopPointService.findOne(this.stop_point_id).subscribe(result => {
      this._favoritesService.addStopPoint(result);
      this.dialog.open(SharedInfoComponent, {
        maxWidth: '500px',
        data: {
          question: 'Dodano przystanek do ulubionych'
        }
      });
    });
  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_close = "Zamknij";
      this._label_header = "Odjazdy dla";
      this._label_date_choose = "Wybierz datę";
      this._label_direction = "KIERUNEK";
      this._label_select = "Pokaż";
      this._label_favorite = "Dodaj do ulubionych";
    } else if (this.settings.language == "en") {
      this._label_close = "Close";
      this._label_header = "Departures for";
      this._label_date_choose = "Select a date";
      this._label_direction = "DIRECTION";
      this._label_select = "Show";
      this._label_favorite = "Add to favorites";
    } else {
      this._label_close = "Ende";
      this._label_header = "Abfahrten von";
      this._label_date_choose = "Wahlen Sie daten";
      this._label_direction = "DIREKTION";
      this._label_select = "Zeig";
      this._label_favorite = "Fügen zu Ihren Favoriten";
    }
  }

}
