import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PassengerMessage } from '../classes/passenger-message';

@Injectable({
  providedIn: 'root'
})
export class PassengerMessageService {

  constructor(private _http: HttpClient) { }

  findActual(carrier_id: string): Observable<PassengerMessage[]> {
    return this._http.get<PassengerMessage[]>(
      environment.api_url + "pass/msgs/" + carrier_id)
  }

}
