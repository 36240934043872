import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { MatTableDataSource, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { Line } from 'src/app/classes/line';
import { LineService } from 'src/app/services/line.service';
import { LineVariantListComponent } from '../line-variant-list/line-variant-list.component';
import { Settings } from 'src/app/classes/settings';

@Component({
  selector: 'app-line-list',
  templateUrl: './line-list.component.html',
  styleUrls: ['./line-list.component.less']
})
export class LineListComponent implements OnInit {

  loading = false;
  carrier_id: string = null;
  settings: Settings = null;

  _label_close = "Zamknij";
  _label_header = "Wybierz linię";
  _label_filter = "Filter...";

  public dataSource: MatTableDataSource<Line> = new MatTableDataSource<Line>([]);

  displayedColumns: string[] = ['name'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<LineListComponent>,
    private dialog: MatDialog,
    private _mainService: LineService) { }

  ngOnInit() {
    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this.updateLabels();

    this.carrier_id = localStorage.getItem("_pass_carrier_id");

    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return data.name.toLowerCase().includes(filter);
    };

    if (this.carrier_id) {
      this.refresh();
    }
  }

  refresh() {
    this.loading = true;
    forkJoin([
      this._mainService.findAll(this.carrier_id)
    ]).subscribe(results => {
      this.dataSource.data = results[0];
      this.loading = false;
    });
  }

  clickLine(line: Line) {
    //this.dialogRef.close();
    localStorage.setItem("_pass_line_id", line.id);
    this.dialog.open(LineVariantListComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_close = "Zamknij";
      this._label_header = "Wybierz linię";
      this._label_filter = "Filtruj...";
    } else if (this.settings.language == "en") {
      this._label_close = "Close";
      this._label_header = "Choose line";
      this._label_filter = "Filter...";
    } else {
      this._label_close = "Ende";
      this._label_header = "Wahlen Sie eine Linien";
      this._label_filter = "Filter...";
    }
  }

}
