import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DeparturesTimetableComponent } from './components/departures/departures-timetable/departures-timetable.component';
import { DeparturesDisplayComponent } from './components/departures/departures-display/departures-display.component';
import { ChangeCarrierComponent } from './components/carrier/change-carrier/change-carrier.component';
import { LineListComponent } from './components/line/line-list/line-list.component';
import { StopPointListComponent } from './components/stop/stop-point-list/stop-point-list.component';
import { LineVariantListComponent } from './components/line/line-variant-list/line-variant-list.component';
import { LineVariantStopPointListComponent } from './components/stop/line-variant-stop-point-list/line-variant-stop-point-list.component';
import { SettingsComponent } from './components/settings/settings.component';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule, MatToolbarModule, MatCheckboxModule, MatMenuModule, MatDialogModule, MatFormFieldModule, MatTooltipModule, MatInputModule, MatIconModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatSortModule, MatRadioModule, MatButtonToggleModule, MatAutocompleteModule, MatProgressSpinnerModule, MatNativeDateModule, MatDatepickerModule, MatChipsModule, MatCardModule, MatListModule, MAT_DIALOG_DEFAULT_OPTIONS, MatSidenavModule, MatDialogRef, MatDialog } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FavoritesListComponent } from './components/favorites/favorites-list/favorites-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RegisterComponent } from './components/account/register/register.component';
import { LoginComponent } from './components/account/login/login.component';
import { SharedInfoComponent } from './components/shared/shared-info/shared-info.component';
import { OpenStopComponent } from './components/help/open-stop/open-stop.component';

@NgModule({
  declarations: [
    AppComponent,
    DeparturesTimetableComponent,
    DeparturesDisplayComponent,
    ChangeCarrierComponent,
    LineListComponent,
    StopPointListComponent,
    LineVariantListComponent,
    LineVariantStopPointListComponent,
    SettingsComponent,
    FavoritesListComponent,
    RegisterComponent,
    LoginComponent,
    SharedInfoComponent,
    OpenStopComponent
  ],
  imports: [
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSidenavModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,

    MatDatepickerModule,
    MatChipsModule,
    MatCardModule,
    MatListModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    LeafletModule.forRoot()
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    MatDatepickerModule,
    MatDialog
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ChangeCarrierComponent,
    DeparturesDisplayComponent,
    DeparturesTimetableComponent,
    LineListComponent,
    LineVariantListComponent,
    SettingsComponent,
    LineVariantStopPointListComponent,
    StopPointListComponent,
    FavoritesListComponent,
    LoginComponent,
    RegisterComponent,
    SharedInfoComponent
  ]
})

export class AppModule {

}
