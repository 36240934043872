export class AccountLoginRequest {
    login: string;
    password: string;

    // symbole
    fav_stop_points: string;
    fav_lines: string;

}

export class AccountLoginResponse {
    login: string;
    password: string;
    name: string;
    perm: string;
    reason: string;

    // symbole
    fav_stop_points: string;
    fav_lines: string;

}