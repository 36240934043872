import { latLng, icon } from "leaflet";

export const environment = {
  production: true,

  map_sw: latLng(49.559598, 20.156575),
  map_ne: latLng(49.867969, 20.712824),
  map_center: [49.706125, 20.421674],
  map_zoom: 15,
  
  logo: "",
  
  line_module: true,

  api_url: 'https://sip.miastolimanowa.pl:8443/api/',
  server_url: 'https://sip.miastolimanowa.pl/',
  carrierSelectGuard: true,
  
  languages_de: true,
  one_carrier_only: true,

  stopIcon: icon({
    iconUrl: 'assets/images/stop.png',
    iconSize: [32, 32]
  }),

  busIcon: icon({
    iconUrl: 'assets/images/32x32.png',
    iconSize: [32, 32]
  }),

  carrier_name_overwrite: "ZKM Limanowa",

};
