import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PassengerImage } from '../classes/passenger-image';

@Injectable({
  providedIn: 'root'
})
export class PassengerImageService {

  constructor(private _http: HttpClient) { }

  findActual(carrier_id: string): Observable<PassengerImage[]> {
    return this._http.get<PassengerImage[]>(
      environment.api_url + "pass/imgs/" + carrier_id)
  }

}
