import { Component, OnInit } from '@angular/core';
import { AccountLoginRequest } from 'src/app/classes/account_login';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SharedInfoComponent } from '../../shared/shared-info/shared-info.component';
import { Md5 } from 'ts-md5/dist/md5';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  public obj: AccountLoginRequest = new AccountLoginRequest();

  constructor(private _accountService: AccountService,
    public dialogRef: MatDialogRef<LoginComponent>,
    public dialog: MatDialog,) { }

  ngOnInit() {
  }

  okClick() {
    this.obj.password = Md5.hashStr(this.obj.password).toString();
    console.log(this.obj.password);
    this._accountService.login(this.obj).subscribe(response => {
      if (response.reason == "OK") {
        localStorage.setItem('_pass_acc_info', JSON.stringify(response));
        this.dialog.open(SharedInfoComponent, {
          maxWidth: '500px',
          data: {
            question: 'Poprawnie zalogowano'
          }
        });
        this.dialogRef.close();
      } else {
        this.dialog.open(SharedInfoComponent, {
          maxWidth: '500px',
          data: {
            question: 'Błąd logowania: ' + response.reason
          }
        });
        this.dialogRef.close();
      }
    })
  }

}
