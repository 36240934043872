import { Injectable } from '@angular/core';
import { LineVariant } from '../classes/line_variant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LineVariantService {

  constructor(private _http: HttpClient) { }

  findAllByLineId(carrier_id: string, line_id: string): Observable<LineVariant[]> {
    let s = environment.api_url + "pass/variants/" + carrier_id + "/line_id/" + line_id;
    console.log("VLIST: " + s);
    return this._http.get<LineVariant[]>(
      s)
  }

  findOne(id: string) {
    return this._http.get<LineVariant>(
      environment.api_url + "pass/variants/one/" + id);
  }

}
