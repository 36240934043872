import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Carrier } from '../classes/carrier';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  constructor(private _http: HttpClient) { }

  findAll(): Observable<Carrier[]> {
    return this._http.get<Carrier[]>(
      environment.api_url + "pass/carriers")
  }

}
