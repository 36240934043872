import { Injectable } from '@angular/core';
import {  MovingVehicle } from '../classes/vehicle';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private _http: HttpClient) { }

  findMovingVehicles(carrier_id: string): Observable<MovingVehicle[]> {
    let url = environment.api_url + "pass/moving_vehicles/" + carrier_id;
    console.log(url)
    return this._http.get<MovingVehicle[]>(url);
  }

}
