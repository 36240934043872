import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountLoginRequest, AccountLoginResponse } from '../classes/account_login';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private _http: HttpClient) { }

  login(t: AccountLoginRequest): Observable<AccountLoginResponse> {
    let url = environment.api_url + "pass_account/login";
    return this._http.post<AccountLoginResponse>(url, t);
  }

  register(t: AccountLoginRequest): Observable<AccountLoginResponse> {
    let url = environment.api_url + "pass_account/register";
    return this._http.post<AccountLoginResponse>(url, t);
  }

  update(t: AccountLoginRequest): Observable<AccountLoginResponse> {
    let url = environment.api_url + "pass_account/update";
    return this._http.post<AccountLoginResponse>(url, t);
  }

}
