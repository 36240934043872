import { Component, OnInit } from '@angular/core';
import { AccountLoginRequest } from 'src/app/classes/account_login';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SharedInfoComponent } from '../../shared/shared-info/shared-info.component';
import { Md5 } from 'ts-md5/dist/md5';
import { AccountService } from 'src/app/services/account.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent implements OnInit {

  public obj: AccountLoginRequest = new AccountLoginRequest();
  public password_2: string = "";

  constructor(private _accountService: AccountService,
    public dialogRef: MatDialogRef<RegisterComponent>,
    public dialog: MatDialog,) { }

  ngOnInit() {
    
  }

  okClick() {
    if (this.obj.password != this.password_2) {
      this.dialog.open(SharedInfoComponent, {
        maxWidth: '500px',
        data: {
          question: 'Hasła nie zgadzają się'
        }
      });
    }

    this.obj.password = Md5.hashStr(this.obj.password).toString();
    console.log(this.obj.password);
    console.log(this.password_2);
    this._accountService.register(this.obj).subscribe(response => {
      if (response.reason == "OK") {
        localStorage.setItem('_pass_acc_info', JSON.stringify(response));
        this.dialog.open(SharedInfoComponent, {
          maxWidth: '500px',
          data: {
            question: 'Poprawnie zarejestrowano i zalogowano'
          }
        });
        this.dialogRef.close();
      } else {
        this.dialog.open(SharedInfoComponent, {
          maxWidth: '500px',
          data: {
            question: 'Błąd logowania: ' + response.reason
          }
        });
        this.dialogRef.close();
      }
    })
  }

}
